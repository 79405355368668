@import "main-menu.css";
@import "devices.css";
@import "competencies.css";
@import "goods.css";

body {
    font-family: Roboto;
}

.color-main {
    color: #ff855b !important;
}

.color-bg-main {
    background-color: #ff855b;
}

.color-labels-borders {
    color: #aaaaaa;
}

.color-text-default {
    color: #444444;
}

.centered {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.centered-x {
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.centered-y {
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.login-main-form {
    width: 543px;
    height: 387px;
    top: 50%;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: FFFFFF;
    border: solid 1px #FFFFFF;
    border-radius: 30px;
    box-shadow: 15px 15px 15px #EBE3D9;
}

.input-login-div {
    border: solid 1px #FFFFFF;
    border-radius: 15px;
    box-shadow: inset 10px 10px 10px #EBE3D9;
    align-items: center;
    display: grid;
}

.input-login-input {
    border: none;
}

.button-send-emotion {
    margin: 10px 10px 0 10px;
}

/* .main {
    display: grid;
    grid-template-columns: 285px auto;
    gap: 20px;
} */

.main {
    margin-left: 300px;
    /* margin-top: 20px; */
    padding-bottom: 30px;
}

@media only screen and (max-width:767px) {
    .main {
        margin-left: 20px;
        /* margin-top: 20px; */
    }
}