.navi-main {
    margin: auto 0;
    height: 100%;
    width: 285px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
}

@media only screen and (max-width:767px) {
    .navi-main {
        display: none;
        margin: auto 0;
        height: 100%;
        width: 30px;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        overflow-x: hidden;
    }
}


.navi-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 37px;
}

.navi-menu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-left: 38px;
}

.navi-item {
    text-decoration: none;
    font-weight: 300;
    margin-bottom: 15px;
    color: white;
}

.navi-item-active {
    font-weight: 600;
}