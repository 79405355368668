.page-devices {
    margin: 20px;
}

.table-devices {
    margin: 20px;
}

.sunny-axis-slider {
    width: 300px;
    margin-top: 10px;
    /* margin-bottom: 20px; */
}